* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background-color: #ddd;
} */
.button {
  padding: 8px 15px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
a {
  color: #464141;
  text-decoration: none;
}
.slider-control-bottomcenter {
  display: none;
}
/* .slide-current {
 border: 5px solid red;
} */

img {
  max-width: 100%;
}
.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 !important;
}

/* .appBar {
  background: #fff;
  height: 60px;
  margin-top: 0;
  padding: 0;
  color: #999;
  box-shadow: 0;
} */

.slick-slide.slick-center img {
  /* transition: 0.3s ease;
  transform: scale(1.5);
  opacity: 1 !important; */
  /* margin: auto !important; */
}
.slick-slide.slick-center section {
  transition: 0.3s ease;
  transform: scale(1.2);
  opacity: 1 !important;
  z-index: 3000;
  /* margin: auto !important; */
}

.slick-next:before,
.slick-prev:before {
  content: "";
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

@page {
  size: auto;
  margin: 5mm;
}

@media print {
  .noprint {
    display: none !important;
  }
  .printMe {
    display: block !important;
  }
}
